<template>
  <div class="home-container">
    <div class="header">
      <van-nav-bar
        v-if="topBarShow"
        @click-left="goBack"
        @click-right="share"
        right-text="分享"
        left-arrow
        :safe-area-inset-top="true"
        :border="false"
      />
    </div>
    <div class="content-box">
      <div :class="topBarShow ? 'content active' : 'content'">
        <div class="banner"></div>
        <div class="title">
          <div class="text"></div>
          <div
            class="frequency"
            v-if="activityData.type === 1 || activityData.type === 2"
          >
            您还有
            <span>{{ gameNum || 0 }}</span
            >次抽奖机会
          </div>
          <div class="frequency" v-else>
            每次消耗
            <span>{{ activityData.quantity }}</span
            >积分
          </div>
        </div>
        <div class="trunTable">
          <div class="dial-animate-wrap">
            <div
              class="dial-bg"
              :style="`-webkit-transition: transform ${config.duration}ms ${config.mode};
          transition: transform ${config.duration}ms ${config.mode};
          -webkit-transform: rotate(${rotateAngle}deg);
              transform: rotate(${rotateAngle}deg);`"
            >
              <img class="bg-img" src="@/assets/dial.png" alt />
              <div class="prizes">
                <div
                  class="prizes-item"
                  v-for="(item, index) in activityData.goods"
                  :key="item"
                  :style="'transform:rotate(' + index * 60 + 'deg);'"
                >
                  <img :src="item.thumb" />
                </div>
              </div>
            </div>
            <div class="dial-run" @click="onClickTurntable"></div>
            <div
              class="dial-mork-wrap"
              @touchmove.prevent.stop
              v-if="isrun"
            ></div>
          </div>
        </div>
        <div class="integralAndRecord">
          <div class="integral">
            我的积分：
            <span>{{ activityData.score || 0 }}</span>
          </div>
          <div class="myRecord" @click="jumpWinning">中奖记录</div>
        </div>
        <div class="recordList">
          <div
            v-if="activityData.fdata"
            :class="'record-box ' + recordAnimationName"
            :style="'--time:' + activityData.fdata.length + 's'"
          >
            <div
              class="record-item"
              v-for="(item, index) in activityData.fdata"
              :key="index"
            >
              <p>{{ item.time }}</p>
              <p>{{ item.nickname }}</p>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
        <div class="dialog-box" v-if="dialog">
          <div class="dialog">
            <div class="dialog-content">{{ winningInformation.title }}</div>
            <div class="goods">
              <img :src="winningInformation.door_image" />
            </div>
            <div class="button" @click="hiddenDialog"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import Api from "@/API";
import { Toast, NavBar } from "vant";
import { defineComponent, computed } from "vue";
import { getPhoneSystem, isWeiChat, getUrlParam } from "@/utils/environment";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
  },
  setup(props) {
    if (getPhoneSystem() === "IOS") {
      //把方法暴露到window上 供ios调用
      window.getToken = getToken;
      window.getGameNum = getGameNum;
    }
    //使用vuex
    const store = useStore();
    //使用路由
    const router = useRouter();
    //创建API对象
    const API = new Api();
    // 中奖对话框显示隐藏
    let dialog = ref(false);
    // 活动数据
    let activityData = ref({});
    // 中奖信息
    let winningInformation = ref({});
    // 顶部栏显示隐藏
    const topBarShow = !isWeiChat();
    // 活动是否开启
    let isOpen = false;
    // 是否显示banner
    let isShowBanner = ref(false);
    // 抽奖次数
    let gameNum = ref(0);
    let isClick = false;
    //获取Token
    function getToken(params) {
      try {
        if (isWeiChat()) {
          const token = getUrlParam("token");
          store.commit("SET_TOKEN", token);
          return false;
        }
        if (getPhoneSystem() === "Android") {
          const token = window.Android.getToken();
          store.commit("SET_TOKEN", token);
        }
        if (getPhoneSystem() === "IOS") {
          store.commit("SET_TOKEN", params);
          getRecord();
          getGameNum();
        }
      } catch (error) {
        Toast.fail("获取Token失败:" + error);
        return "error";
      }
    }
    // 获取活动数据
    const getRecord = async () => {
      let res = await API.getTruntableData({
        token: store.state.Token,
      });
      if (res) {
        isOpen = true;
        activityData.value = res.data;
        if (res.data.fdata && res.data.fdata.length >= 5) {
          recordAnimationName.value = "recordRotation";
        }
        isShowBanner.value =
          res.data.thumb.indexOf("upload") === -1 ? false : true;
      }
    };
    // 获取抽奖次数
    const getGameNum = async () => {
      const res = await API.getAcquisitionTimes({
        token: store.state.Token,
        game_type: 1,
      });
      if (res) {
        gameNum.value = res.data.game_num;
      }
    };
    // 中奖对话框隐藏
    const hiddenDialog = () => {
      dialog.value = false;
      getRecord();
      jumpShop();
    };
    // 跳转好店商品列表
    const jumpShop = () => {
      const {
        business_id: shopId,
        extension_id,
        business_name: shopName,
      } = winningInformation.value;
      try {
        if (isWeiChat()) {
          addExposure(3);
          wx.miniProgram.navigateTo({
            url: `/X/pages/findAGoodStoreProductList/findAGoodStoreProductList?bodyName=${shopName}&source=3&extension_id=${extension_id}&cid=${shopId}&gameType=1`,
          });
          return;
        }
        if (getPhoneSystem() === "Android") {
          addExposure(1);
          window.Android.turnToShop(shopId, extension_id, String(shopName));
          return;
        }
        if (getPhoneSystem() === "IOS") {
          addExposure(2);
          window.webkit.messageHandlers.shareToWX.postMessage({
            shopId,
            extension_id,
            shopName: String(shopName),
          });
          return;
        }
      } catch (error) {
        Toast.fail("出现了一个交互错误");
      }
    };
    // 增加曝光
    const addExposure = async (os_from) => {
      const { extension_id } = winningInformation.value;
      await API.addExposure({
        token: store.state.Token,
        gameType: 1,
        type: 1,
        extension_id,
        os_from,
      });
    };
    // 跳转中奖记录
    const jumpWinning = () => {
      router.push({ name: "luckDrawWinningList" });
    };
    // 页面分享
    const share = () => {
      try {
        if (getPhoneSystem() === "Android") {
          window.Android.shareToWX();
        }
        if (getPhoneSystem() === "IOS") {
          window.webkit.messageHandlers.shareToWX.postMessage(null);
        }
      } catch (error) {
        Toast.fail("出现了一个交互错误");
      }
    };
    // 页面返回
    const goBack = () => {
      try {
        if (getPhoneSystem() === "Android") {
          window.Android.goBack();
        }
        if (getPhoneSystem() === "IOS") {
          window.webkit.messageHandlers.goBack.postMessage(null);
        }
      } catch (error) {
        Toast.fail("出现了一个交互错误");
      }
    };
    // -----------------大转盘-----------------
    let isrun = false;
    let rotateAngle = ref(0); // 旋转角度
    const config = {
      duration: 4000, // 总旋转时间 ms级
      circle: 8, // 旋转圈数
      mode: "ease-in-out", // 由快到慢 惯性效果都省了
    };
    let cricleAdd = 1; // 第几次抽奖
    const recordAnimationName = ref(""); // 中奖列表轮播动画class
    // 点击抽奖
    const onClickTurntable = () => {
      if (isrun) return;
      if (!isOpen) {
        return Toast.fail("活动未开启或已结束");
      }
      if (isClick) return;
      isClick = true;
      luckDraw();
    };
    // 点击抽奖
    const luckDraw = async () => {
      try {
        let res = await API.getLuckDraw({
          token: store.state.Token,
        });
        getGameNum();
        // 判断奖品索引 执行动画效果
        activityData.value.goods.forEach((item, index) => {
          if (item.id === res.data.yes.id) {
            run(index);
          }
        });
        // 中奖信息
        winningInformation.value = res.data.yes;
      } catch (err) {
        isClick = false;
      }
    };
    // 转盘旋转 index:抽中奖品索引
    const run = (index) => {
      if (isrun) return;
      isrun = true;
      rotateAngle.value = config.circle * 360 * cricleAdd - (0 + index * 60);
      // 圈数位置解析
      // config.circle * 360 * cricleAdd 顺时针总圈数/累积总圈数
      // 30 + index * 60 ===> (奖品位置 === index * 60) (指针中间位置 === 30)
      cricleAdd++;
      setTimeout(() => {
        dialog.value = true;
        isrun = false;
        isClick = false;
      }, config.duration);
    };
    onMounted(() => {
      if (isWeiChat() || getPhoneSystem() === "Android") {
        getToken();
        getRecord();
        getGameNum();
      }
      if (getPhoneSystem() === "IOS" && isWeiChat() === false) {
        window.webkit.messageHandlers.getToken.postMessage(null);
      }
    });
    return {
      activityData,
      jumpWinning,
      winningInformation,
      isrun,
      rotateAngle,
      config,
      onClickTurntable,
      hiddenDialog,
      dialog,
      goBack,
      recordAnimationName,
      share,
      topBarShow,
      isShowBanner,
      gameNum,
    };
  },
});
</script>
<style lang="scss">
body {
  height: 100%;
  background: #ff3b5b;
}
.home-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .header {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    .van-nav-bar {
      background: rgba(255, 255, 255, 0);
      .van-nav-bar__content {
        .van-nav-bar__left {
          i {
            color: #fff;
          }
        }
        .van-nav-bar__title {
          color: #fff;
        }
        .van-nav-bar__right {
          .van-nav-bar__text {
            color: #fff;
          }
        }
      }
    }
  }
  .content-box {
    overflow-y: scroll;
    .content.active {
      margin-top: -269px;
    }
    .content {
      background: url("../../assets/home-bg.png") no-repeat;
      background-size: 100% 100%;
      background-color: #ff3b5b;
      flex-shrink: 0;
      padding-top: 160px;
      padding-bottom: 50px;
      margin-top: -369px;
      .banner {
        width: 695px;
        height: 269px;
        border-radius: 18px;
        overflow: hidden;
        margin: 0 auto 128px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        margin-bottom: 32px;
        .text {
          height: 85px;
        }
        .frequency {
          width: 316px;
          height: 54px;
          border-radius: 9px;
          background: #f2004c;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 54px;
          margin: 0 auto;
          letter-spacing: 3px;
          text-align: center;
          span {
            color: #fff600;
            font-weight: bold;
          }
        }
      }
      .trunTable {
        width: 622px;
        height: 622px;
        margin: 0 auto 206px;
      }
      .integralAndRecord {
        height: 60px;
        padding: 13px;
        position: relative;
        margin-bottom: 51px;
        .integral {
          width: 274px;
          height: 60px;
          padding: 0 25px;
          background: #f2004c;
          border-radius: 30px;
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 60px;
          letter-spacing: 3px;
          span {
            color: #fff600;
            font-weight: bold;
          }
        }
        .myRecord {
          width: 161px;
          height: 59px;
          background: rgba(212, 31, 27, 0.8);
          border-radius: 30px 0px 0px 30px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
          line-height: 60px;
          text-align: center;
          padding-left: 10px;
          letter-spacing: 2px;
        }
      }
      .recordList {
        width: 621px;
        height: 279px;
        margin: 0 auto;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        overflow: hidden;
        padding: 20px 0;
        box-sizing: border-box;
        background: url("../../assets/record-list-bg.png") no-repeat;
        background-size: cover;
        .record-box {
          overflow: hidden;
          .record-item {
            line-height: 60px;
            text-align: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
          }
        }
        .recordRotation {
          animation: roll var(--time) linear infinite;
        }
        @keyframes roll {
          from {
            transform: translateY(240px);
          }
          to {
            transform: translateY(-100%);
          }
        }
      }
      .dialog-box {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9;
        .dialog {
          width: 634px;
          height: 793px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url("../../assets/win-the-prize-goods.png") no-repeat;
          background-size: cover;
          padding-top: 230px;
          box-sizing: border-box;
          text-align: center;
          .dialog-content {
            width: 400px;
            font-size: 36px;
            font-weight: 400;
            color: #eb4a2d;
            margin: 0 auto 20px auto;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .goods {
            width: 260px;
            height: 260px;
            margin: 0 auto 44px;
            img {
              max-width: 100%;
              max-height: 100%;
              display: block;
              margin: 0 auto;
            }
          }
          .button {
            width: 400px;
            height: 90px;
            margin: auto;
            position: absolute;
            bottom: 82px;
            left: 0;
            right: 0;
          }
        }
      }
      .dial-mork-wrap {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
      }
      .dial-animate-wrap {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .dial-bg {
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          .bg-img {
            width: 100%;
            height: 100%;
            display: block;
          }
          .prizes {
            width: 100px;
            height: 75%;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            .prizes-item {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              img {
                width: 100px;
                height: 100px;
              }
            }
          }
        }
        .dial-run {
          width: 196px;
          height: 224px;
          background: url("../../assets/dial-center-icon.png") no-repeat center;
          background-size: contain;
          position: absolute;
          left: 0;
          right: 0;
          top: 184px;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}
</style>